import { default as _91_46_46_46slug_93TF8WIVnzL5Meta } from "/Users/manel/devel/EcosystemFrontend/pages/[...slug].vue?macro=true";
import { default as _91slug_93jMfs1pDIpIMeta } from "/Users/manel/devel/EcosystemFrontend/pages/car/[slug].vue?macro=true";
import { default as indexMuQQi91llhMeta } from "/Users/manel/devel/EcosystemFrontend/pages/preview/index.vue?macro=true";
import { default as catalog8ZJ9AvWrieMeta } from "/Users/manel/devel/EcosystemFrontend/pages/subscription/catalog.vue?macro=true";
import { default as customizationpxdTcoDaPTMeta } from "/Users/manel/devel/EcosystemFrontend/pages/subscription/customization.vue?macro=true";
import { default as driver_45detailsDl8zgXKHmNMeta } from "/Users/manel/devel/EcosystemFrontend/pages/subscription/driver-details.vue?macro=true";
import { default as successHnUR7Qllt2Meta } from "/Users/manel/devel/EcosystemFrontend/pages/subscription/success.vue?macro=true";
import { default as thank_45youiui4UCQfNwMeta } from "/Users/manel/devel/EcosystemFrontend/pages/thank-you.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93TF8WIVnzL5Meta?.name ?? "slug___es___default",
    path: _91_46_46_46slug_93TF8WIVnzL5Meta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93TF8WIVnzL5Meta || {},
    alias: _91_46_46_46slug_93TF8WIVnzL5Meta?.alias || [],
    redirect: _91_46_46_46slug_93TF8WIVnzL5Meta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TF8WIVnzL5Meta?.name ?? "slug___es",
    path: _91_46_46_46slug_93TF8WIVnzL5Meta?.path ?? "/es/:slug(.*)*",
    meta: _91_46_46_46slug_93TF8WIVnzL5Meta || {},
    alias: _91_46_46_46slug_93TF8WIVnzL5Meta?.alias || [],
    redirect: _91_46_46_46slug_93TF8WIVnzL5Meta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMfs1pDIpIMeta?.name ?? "car-slug___es___default",
    path: _91slug_93jMfs1pDIpIMeta?.path ?? "/car/:slug()",
    meta: _91slug_93jMfs1pDIpIMeta || {},
    alias: _91slug_93jMfs1pDIpIMeta?.alias || [],
    redirect: _91slug_93jMfs1pDIpIMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/car/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93jMfs1pDIpIMeta?.name ?? "car-slug___es",
    path: _91slug_93jMfs1pDIpIMeta?.path ?? "/es/car/:slug()",
    meta: _91slug_93jMfs1pDIpIMeta || {},
    alias: _91slug_93jMfs1pDIpIMeta?.alias || [],
    redirect: _91slug_93jMfs1pDIpIMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/car/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexMuQQi91llhMeta?.name ?? "preview___es___default",
    path: indexMuQQi91llhMeta?.path ?? "/preview",
    meta: indexMuQQi91llhMeta || {},
    alias: indexMuQQi91llhMeta?.alias || [],
    redirect: indexMuQQi91llhMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexMuQQi91llhMeta?.name ?? "preview___es",
    path: indexMuQQi91llhMeta?.path ?? "/es/preview",
    meta: indexMuQQi91llhMeta || {},
    alias: indexMuQQi91llhMeta?.alias || [],
    redirect: indexMuQQi91llhMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: catalog8ZJ9AvWrieMeta?.name ?? "subscription-catalog___es___default",
    path: catalog8ZJ9AvWrieMeta?.path ?? "/subscription/catalog",
    meta: catalog8ZJ9AvWrieMeta || {},
    alias: catalog8ZJ9AvWrieMeta?.alias || [],
    redirect: catalog8ZJ9AvWrieMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/catalog.vue").then(m => m.default || m)
  },
  {
    name: catalog8ZJ9AvWrieMeta?.name ?? "subscription-catalog___es",
    path: catalog8ZJ9AvWrieMeta?.path ?? "/es/subscription/catalog",
    meta: catalog8ZJ9AvWrieMeta || {},
    alias: catalog8ZJ9AvWrieMeta?.alias || [],
    redirect: catalog8ZJ9AvWrieMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/catalog.vue").then(m => m.default || m)
  },
  {
    name: customizationpxdTcoDaPTMeta?.name ?? "subscription-customization___es___default",
    path: customizationpxdTcoDaPTMeta?.path ?? "/subscription/customization",
    meta: customizationpxdTcoDaPTMeta || {},
    alias: customizationpxdTcoDaPTMeta?.alias || [],
    redirect: customizationpxdTcoDaPTMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/customization.vue").then(m => m.default || m)
  },
  {
    name: customizationpxdTcoDaPTMeta?.name ?? "subscription-customization___es",
    path: customizationpxdTcoDaPTMeta?.path ?? "/es/subscription/customization",
    meta: customizationpxdTcoDaPTMeta || {},
    alias: customizationpxdTcoDaPTMeta?.alias || [],
    redirect: customizationpxdTcoDaPTMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/customization.vue").then(m => m.default || m)
  },
  {
    name: driver_45detailsDl8zgXKHmNMeta?.name ?? "subscription-driver-details___es___default",
    path: driver_45detailsDl8zgXKHmNMeta?.path ?? "/subscription/driver-details",
    meta: driver_45detailsDl8zgXKHmNMeta || {},
    alias: driver_45detailsDl8zgXKHmNMeta?.alias || [],
    redirect: driver_45detailsDl8zgXKHmNMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/driver-details.vue").then(m => m.default || m)
  },
  {
    name: driver_45detailsDl8zgXKHmNMeta?.name ?? "subscription-driver-details___es",
    path: driver_45detailsDl8zgXKHmNMeta?.path ?? "/es/subscription/driver-details",
    meta: driver_45detailsDl8zgXKHmNMeta || {},
    alias: driver_45detailsDl8zgXKHmNMeta?.alias || [],
    redirect: driver_45detailsDl8zgXKHmNMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/driver-details.vue").then(m => m.default || m)
  },
  {
    name: successHnUR7Qllt2Meta?.name ?? "subscription-success___es___default",
    path: successHnUR7Qllt2Meta?.path ?? "/subscription/success",
    meta: successHnUR7Qllt2Meta || {},
    alias: successHnUR7Qllt2Meta?.alias || [],
    redirect: successHnUR7Qllt2Meta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: successHnUR7Qllt2Meta?.name ?? "subscription-success___es",
    path: successHnUR7Qllt2Meta?.path ?? "/es/subscription/success",
    meta: successHnUR7Qllt2Meta || {},
    alias: successHnUR7Qllt2Meta?.alias || [],
    redirect: successHnUR7Qllt2Meta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/subscription/success.vue").then(m => m.default || m)
  },
  {
    name: thank_45youiui4UCQfNwMeta?.name ?? "thank-you___es___default",
    path: thank_45youiui4UCQfNwMeta?.path ?? "/thank-you",
    meta: thank_45youiui4UCQfNwMeta || {},
    alias: thank_45youiui4UCQfNwMeta?.alias || [],
    redirect: thank_45youiui4UCQfNwMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: thank_45youiui4UCQfNwMeta?.name ?? "thank-you___es",
    path: thank_45youiui4UCQfNwMeta?.path ?? "/es/thank-you",
    meta: thank_45youiui4UCQfNwMeta || {},
    alias: thank_45youiui4UCQfNwMeta?.alias || [],
    redirect: thank_45youiui4UCQfNwMeta?.redirect,
    component: () => import("/Users/manel/devel/EcosystemFrontend/pages/thank-you.vue").then(m => m.default || m)
  }
]